module.exports = {
	primaryLinks: {
		en: [
			{
				title: `Work`,
				link: {
					url: `/en/projects/`,
				},
			},
			{
				title: `Team`,
				link: {
					url: `/en/team/`,
				},
			},
			{
				title: `Agency`,
				link: {
					url: `/en/agency/`,
				},
			},
			{
				title: `Blog`,
				link: {
					url: `/en/blog/`,
				},
			},
			{
				title: `Contact`,
				link: {
					url: `/en/contact/`,
				},
			},
		],
		fr: [
			{
				title: `Projets`,
				link: {
					url: `/fr/projets/`,
				},
			},
			{
				title: `L’équipe`,
				link: {
					url: `/fr/equipe/`,
				},
			},
			{
				title: `L’agence`,
				link: {
					url: `/fr/agence/`,
				},
			},
			{
				title: `Blog`,
				link: {
					url: `/fr/blog/`,
				},
			},
			{
				title: `Contact`,
				link: {
					url: `/fr/contact/`,
				},
			},
		],
	},
	secondaryLinks: {
		en: [
			{
				title: `Strategy`,
				link: {
					url: `/en/digital-strategy-agency/`,
				},
			},
			{
				title: `Branding`,
				link: {
					url: `/en/swiss-branding-agency/`,
				},
			},
			{
				title: `Web solutions`,
				link: {
					url: `/en/swiss-web-agency/`,
				},
			},
		],
		fr: [
			{
				title: `Stratégie`,
				link: {
					url: `/fr/agence-strategie-digitale/`,
				},
			},
			{
				title: `Branding`,
				link: {
					url: `/fr/agence-branding-suisse/`,
				},
			},
			{
				title: `Solutions web`,
				link: {
					url: `/fr/agence-web-suisse/`,
				},
			},
		],
	},
	tertiaryLinks: {
		en: [
			{
				title: 'Our Culture',
				link: {
					url: '/en/culture/',
				},
			},
			// {
			// 	title: 'Join Us',
			// 	link: {
			// 		url: '/en/jobs/',
			// 	},
			// },
		],
		fr: [
			{
				title: 'Culture',
				link: {
					url: '/fr/culture/',
				},
			},
			// {
			// 	title: 'Rejoindre',
			// 	link: {
			// 		url: '/fr/rejoindre/',
			// 	},
			// },
		],
	},
};
